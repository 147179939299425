.Toastify__toast--dark {
    background: rgb(0, 0, 0, 0.71) !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 11px 7px rgba(199, 199, 199, 0.25);
    backdrop-filter: blur(9px);
  }
  
  .Toastify__progress-bar--dark {
    background: greenyellow !important;
  }

   @media screen and (max-width:767px) {
    .Toastify__toast-container {

      width:240px !important
    }
    
    
  }
